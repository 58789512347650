@font-face {
  font-family: 'BKoodkBd';
  font-style: normal;
  font-weight: 400;
  src: local('BKoodkBd'), local('BKoodkBd'),url('../_assets/font/BKoodkBd.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'BYekan';
  font-style: normal;
  font-weight: 400;
  src: local('BYekan'), local('BYekan'),url('../_assets/font/BYekan.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

* {
  font-family: 'BYekan', sans-serif
}

.t3 {
  font-size: 20pt;
}