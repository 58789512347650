//colors
$color-mainbackground: #fff;
$color-navbarbg: #fff;
$color-sidebar:#FFFFFF;
$color-sidebaricons:#0d6cac;
$color-navbaricons:rgb(41, 41, 41);

$color-blue1:#34a0d9;
$color-blueLight1:rgb(0, 203, 225);

$color-red: #FF0000;
$color-green: #00FF00;
$color-blue: #0000FF;
$color-white: #ededed;


// Predefined Break-points
$media-xs: 0px;
$media-sm: 600px;
$media-lg-k: 720px;
$media-md: 960px;
$media-xl-k: 1080px;
$media-lg: 1280px;
$media-xl: 1920px;

//z-index
$zindex-mobilestepper: 1000;
$zindex-appbar: 1100;
$zindex-drawer: 1200;
$zindex-modal: 1300;
$zindex-snackbar: 1400;
$zindex-tooltip: 1500;


//theme variables
$themespacingunit:8px;
$drawerWidth:200px;


//
$borderradius-shape:10px;


@mixin transition($args...) {
   -webkit-transition: $args;
   -moz-transition: $args;
   -ms-transition: $args;
   -o-transition: $args;
   transition: $args;
 }

@mixin numberFont {
   font-family: "Helvetica", "Arial", sans-serif;
}

// @mixin box-shadow($args...) {
//    -webkit-box-shadow: $args;
//    box-shadow: $args;
//  }

@mixin box-shadow($point) {
   @if $point == sm {
      box-shadow: 0 0px 5px 2px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
      -webkit-box-shadow: 0 0px 5px 2px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
   }
}

@mixin breakpoint($point) {
    @if $point == xl {
      @media (min-width: $media-xl) { @content ; }
   }
   @else if $point == -xl {
      @media (max-width: $media-xl) { @content ; }
   }
    @else if $point == lg-xl {
      @media (min-width: $media-lg) and (max-width: $media-xl) { @content ; }
   }

   @if $point == lg {
      @media (min-width: $media-lg) { @content ; }
   }
   @else if $point == -lg {
      @media (max-width: $media-lg) { @content ; }
   }
   @else if $point == md-lg {
      @media (min-width: $media-md) and (max-width: $media-lg) { @content ; }
   }

   @if $point == xlk {
      @media (min-width: $media-xl-k) { @content ; }
   }
   @else if $point == -xlk {
      @media (max-width: $media-xl-k) { @content ; }
   }


   @if $point == md {
      @media (min-width: $media-md) { @content ; }
   }
   @else if $point == -md {
      @media (max-width: $media-md) { @content ; }
   }
   @else if $point == sm-md {
      @media (min-width: $media-sm) and (max-width: $media-md) { @content ; }
   }

   @if $point == lgk {
      @media (min-width: $media-lg-k) { @content ; }
   }
   @else if $point == -lgk {
      @media (max-width: $media-lg-k) { @content ; }
   }

   @if $point == sm {
      @media (min-width: $media-sm) { @content ; }
   }
   @else if $point == -sm {
      @media (max-width: $media-sm) { @content ; }
   }
   @else if $point == xs-sm {
      @media (min-width: $media-xs) and (max-width: $media-sm) { @content ; }
   }

   @if $point == xs {
      @media (min-width: $media-xs) { @content ; }
   }
   @else if $point == -xs {
      @media (max-width: $media-xs) { @content ; }
   }
 }