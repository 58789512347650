@import '../../_styles/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;


    &>div:first-child {
        margin: inherit;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    @include breakpoint(-md) {
        flex-direction: column;
    }

    .pageStyle {
        flex-grow: 1;
    }

    .keypadcontainer {
        direction: ltr;
        padding: 30px;
        min-width: 400px;

        .keypad {
            margin: auto;
        }

        @include breakpoint(-sm) {
            padding: 0px;
            min-width: 100%;
        }
    }

}

.active {
    background-color: #e9f7ff;
}