@import '../../_styles/vars.scss';

.container {
    padding: 20px;

    .title {
        font-size: 18pt;
        background-color: #ff5050;
        text-align: center;
        color: white;
    }

    .content {
        font-size: 12pt;
        padding: 20px;
        color: #797979;
        min-width: 300px;
        text-align: center;
    }

    .actions {
        display: flex;
        justify-content: space-evenly;
        margin: 0px 15px 15px 15px;
    }
}

