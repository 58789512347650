@import '../_styles/vars.scss';

.container{
    display: flex;
    padding: 30px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}


.cards {
    border-radius: 25px;
    color: white;
    display: flex;
    min-width: 300px;
    min-height: 100px;
    justify-content: space-evenly;
    padding: 10px;
    margin: 15px;
    flex-wrap: wrap;
    align-items: center;


    @include breakpoint(-md) {
        min-width: 40%;
        min-height: 100px;
    }

    @include breakpoint(-sm) {
        min-width: 100%;
        min-height: 100px;
    }


    .icon {
        font-size: 40px;
        background-color: white;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        text-align: center;
    }



    .content {
        display: flex;
        flex-direction: column;
        .header {

        }

        .body {

        }
    }
}

.cards:nth-child(1){
    background-color: #24A1EF;
    .icon {
        color: #24A1EF;
    }
}
.cards:nth-child(2){
    background-color: #00C78B;
    .icon {
        color: #00C78B;
    }
}
.cards:nth-child(3){
    background-color: #FF4D6F;
    .icon {
        color: #FF4D6F;
    }
}
.cards:nth-child(4){
    background-color: #FF9F28;
    .icon {
        color: #FF9F28;
    }
}