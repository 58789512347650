@import '../_styles/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.keyPadContainer {
    position: absolute;
    bottom: 0px;
    right: -1px;
    left: 0px;
    z-index: 12;
    overflow: hidden;
    direction: ltr;

    .keyPad {
        direction: ltr;
        background-color: #e0e0e0;
    }
}

.container::after {
    content: "";
    //background-image: url('../_assets/images/bg.jpg');
    background-size: cover;

    background-position: bottom;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.slick-slide img {
    margin: auto;
}

.topSlider {
    position: relative;

    &,
    .video,
    .slider {
        height: calc(100vw * 9/16) !important;

    //     @include breakpoint(-lg) {
    //         height: 720px !important;
    //     }

    //     @include breakpoint(-xlk) {
    //         height: 607px !important;
    //     }

    //     @include breakpoint(-md) {
    //         height: 540px !important;
    //     }

    //     @include breakpoint(-lgk) {
    //         height: 405px !important;
    //     }

    //     @include breakpoint(-sm) {
    //         height: 180px !important;
    //     }
     }

    .video {
        width: 100%;
        object-fit: fill;
        z-index: 2;
        position: absolute;

        video {
            object-fit: fill;
        }

        // @include breakpoint(-md) {
        //     display: none;
        // }


        height: 100%;
    }

    .slider {
        position: absolute;
        width: 100%;

        .img {
            width: 100%;
            height: 100%;
        }
    }

    .discountSwitch,
    .cancelButton {
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: #ffffff9c;
        margin: 10px;
        padding: 5px;
        border-radius: 10px;
        z-index: 2;

        .discountSign {
            background-image: url('../_assets/images/discount.svg');
            background-size: cover;
            margin: 5px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12pt;
            height: 35px;
            padding: 0px 3px 0px 0px;
        }
    }


    .cancelButton {
        left: auto;
        right: 0px;
    }
}

.slider {
    background-color: #F6F6F6;
    overflow: hidden;
    z-index: 1;

    .sliderItem {
        padding: 10px 0px;

        .img {
            width: 150px;
            height: 150px;
            margin: auto;
            border-radius: 50%;

            @include breakpoint(-lg) {
                width: 130px;
                height: 130px;
            }

            @include breakpoint(-md) {
                width: 100px;
                height: 100px;
            }

            @include breakpoint(-sm) {
                width: 80px;
                height: 80px;
            }
        }
    }
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  //  min-height: 900px;

    @include breakpoint(-lgk) {
       // min-height: 750px;
    }

    .identity {
        display: flex;
        justify-content: space-around;
        padding: 10px 0px;

        .logo {
            img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: white;

                @include breakpoint(-lg) {
                    width: 180px;
                    height: 180px;
                }

                @include breakpoint(-md) {
                    width: 150px;
                    height: 150px;
                }

                @include breakpoint(-lgk) {
                    width: 110px;
                    height: 110px;
                }

                @include breakpoint(-sm) {
                    width: 75px;
                    height: 75px;
                }
            }
        }

        .name {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30pt;
            font-weight: bold;

            @include breakpoint(-lg) {
                font-size: 25pt;
            }

            @include breakpoint(-md) {
                font-size: 20pt;
            }

            @include breakpoint(-sm) {
                font-size: 15pt;
            }
        }
    }

    .inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;

        .touch {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            flex-direction: column;
            padding: 50px 0px;
            cursor: pointer;
            min-height: 400px;

            img {
                width: 350px;
            }

            div {
                font-size: 20pt;
            }

            @include breakpoint(-lg) {
                img {
                    width: 310px;
                }

                div {
                    font-size: 20pt;
                }
            }

            @include breakpoint(-md) {
                img {
                    width: 230px;
                }

                div {
                    font-size: 17pt;
                }
            }

            @include breakpoint(-lgk) {
                img {
                    width: 180px;
                }

                div {
                    font-size: 15pt;
                }
            }


            @include breakpoint(-sm) {
                min-height: 0px;
                padding: 25px 0px;

                img {
                    width: 110px;
                }

                div {
                    font-size: 14pt;
                }
            }

            &.hide {
                opacity: 0;
                //display: none;
            }
        }

        .selecttakeaway {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;

            div {
                border-radius: 5px;
                background-color: $color-blue1;
                height: 400px;
                width: 300px;
                padding: 15px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                margin: 15px;
                cursor: pointer;

                &:hover {
                    background-color: $color-blue1;
                }

                span {
                    font-size: 35pt;
                }

                img {
                    width: 100px;
                }

                @include breakpoint(-lg) {
                    height: 350px;
                    width: 250px;

                    span {
                        font-size: 25pt;
                    }

                    img {
                        width: 100px;
                    }
                }

                @include breakpoint(-md) {
                    height: 300px;
                    width: 200px;

                    span {
                        font-size: 20pt;
                    }

                    img {
                        width: 80px;
                    }
                }

                @include breakpoint(-sm) {
                    height: 250px;
                    width: 200px;

                    span {
                        font-size: 17pt;
                    }

                    img {
                        width: 60px;
                    }
                }
            }

            &.hide {
                opacity: 0;
                //display: none;
            }
        }
    }
}

.wellcome {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .ratingAction {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #7b7979;
        font-size: 30pt;
        border-bottom: 1px solid #7b7979;
        cursor: pointer;
        width: 200px;
        height: 200px;
        background-color: $color-blue1;
        border-radius: 50%;
        position: absolute;
        top: 35px;
        left: 35px;
        flex-direction: column;
        color: white;
        font-size: 25pt;

        img {
            width: 50%;
        }


        @include breakpoint(-lg) {
            font-size: 15pt;
            width: 150px;
            height: 150px;
        }

        @include breakpoint(-xlk) {
            font-size: 15pt;
            width: 150px;
            height: 150px;
        }

        @include breakpoint(-md) {
            font-size: 14pt;
            width: 130px;
            height: 130px;
        }

        @include breakpoint(-lgk) {
            font-size: 14pt;
            width: 130px;
            height: 130px;
        }

        @include breakpoint(-sm) {
            font-size: 12pt;
            width: 120px;
            height: 120px;
        }

        &:hover {
            background-color: #bdbdbd;
        }
    }
}

.headerGroup {
    display: flex;
    margin: 15px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: white;
    display: flex;

    @include breakpoint(-sm) {
        flex-direction: column;
    }

    .SelectGroup {
        width: calc(100% - 200px);
        flex-grow: 1;
        padding: 15px;
        position: relative;

        @include breakpoint(-sm) {
            width: 100%;
        }

        .navright,
        .navleft {
            position: absolute;
            top: calc(50% - 20px);

            svg {
                font-size: 85px;
                margin: -25px;
                cursor: pointer;

                @include breakpoint(-md) {
                    font-size: 40px;
                }
            }
        }

        .navright {
            right: 20px;
        }

        .navleft {
            left: 20px;
        }

        .groupSlider {
            margin-right: 50px;
            margin-left: 50px;

            @include breakpoint(-md) {
                margin-right: 25px;
                margin-left: 25px;
            }

            @include breakpoint(-lgk) {
                margin-right: 20px;
                margin-left: 20px;
            }

            .groupSliderItem {
                text-align: center;
                padding: 10px 0px 5px 0px;
                font-size: 15pt;

                @include breakpoint(-lgk) {
                    font-size: 13pt;
                }

                div {
                    background-color: #E4E4E4;
                    border-radius: 15px;
                    padding: 15px;
                    width: 90px !important;
                    height: 90px;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    margin: auto;

                    @include breakpoint(-md) {
                        border-radius: 10px;
                        padding: 10px;
                        width: 65px !important;
                        height: 65px;
                    }

                    &:hover {
                        cursor: pointer;
                        -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                        box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                    }

                    .img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .cart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 10px 10px 10px;
        // margin-right: 20px;
        cursor: pointer;
        border-radius: 35px;
        flex-grow: 0;

        &:hover .img {
            -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        }

        .img {
            background-color: $color-blue1;
            border-radius: 15px;
            padding: 15px;
            width: 90px !important;
            height: 90px;
            margin: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &>img {
                width: 67px;
            }

            span {
                position: absolute;
                bottom: 18.5px;
                right: 18.5px;
                font-size: 16pt;
                color: white;
                font-weight: bold;
                @include numberFont;
            }
        }


        &>span {
            border: 1.5px solid #9999;
            border-radius: 5px;
            padding: 0px 3px;
            display: flex;


            &>hr {
                height: calc(100% - 6px);
                width: 1px;
                margin: 3px 3px 3px 5px;
                color: #eee;
            }

            &>span {
                color: #999;
            }

            &>span:first-of-type {
                @include numberFont;
                line-height: 25px;
            }
        }
    }
}

.SelectProduct {
    padding: 15px 0px;
    margin: 1rem 0px 0rem 0px;
    position: relative;

    @include breakpoint(-md) {
        margin: 0px;
    }

    .navleft,
    .navright {
        position: absolute;
        top: calc(50% - 2rem);
        cursor: pointer;

        svg {
            font-size: 50pt;

            @include breakpoint(-md) {
                font-size: 30pt;
            }
        }

        &:hover>svg,
        &:active>svg {
            color: #535353;
        }
    }

    .navleft {
        left: 0px;
    }

    .navright {
        right: 0px;
    }


    .productSlider {
        margin: 0px 50px;

        @include breakpoint(-md) {
            margin: 0px 30px;
        }

        .card {
            width: 200px;
            margin: 5px auto;
            background-color: white;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);


            @include breakpoint(-md) {
                width: 90%;
                max-width: 175px;
            }

            &:hover {
                -webkit-box-shadow: 0 0px 5px 2px rgba(0, 207, 255, 0.33), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 0px 5px 2px rgba(0, 207, 255, 0.33), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            }

            .name {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 75px;
                line-height: 20px;
                padding: 5px;
                font-size: 15pt;

                @include breakpoint(-lgk) {
                    font-size: 10pt;
                    padding:0px 5px;
                    height: 57px;
                    line-height: 17px;
                }
            }

            .img {
                width: 100%;
                height: 150px;

                @include breakpoint(-md) {
                    height: 130px;
                }

                @include breakpoint(-lgk) {
                     height: 120px;
                }
            }

            .prices {
                display: flex;
                justify-content: space-around;

                .percentage {
                    background-image: url('../_assets/images/discount.svg');
                    background-size: cover;
                    margin: 5px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12pt;
                    height: 35px;
                    padding: 0px 3px 0px 0px;
                }

                .price {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    span:first-of-type {
                        font-size: 16pt;
                        line-height: 18px;
                        margin-top: 10px;
                        @include numberFont;

                        @include breakpoint(-lgk) {
                            margin-top: 5px;
                            font-size: 14pt;
                        }
                    }

                    span:last-of-type {
                        font-size: 10pt;
                        color: #9D9D9D;
                        padding: 3px 0px;
                    }
                }

                .mainprice {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .mainprice:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    right: 0;
                    border-top: 1px solid;
                    border-color: inherit;
                    color: red;

                    -webkit-transform: rotate(-5deg);
                    -moz-transform: rotate(-5deg);
                    -ms-transform: rotate(-5deg);
                    -o-transform: rotate(-5deg);
                    transform: rotate(-5deg);
                }
            }

            .cnt {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                padding-bottom: 18px;

                @include breakpoint(-md) {
                    padding-bottom: 5px;
                }

                .btn {
                    background-color: white;
                    border: 1px solid $color-blue1;
                    color: $color-blueLight1;
                    height: 45px;
                    width: 45px;

                    @include breakpoint(-lgk) {
                        height: 35px;
                        width: 35px;
                    }

                }

                &>span {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                    font-weight: bold;
                    @include numberFont;
                    font-size: 18pt;
                    color: #747474;

                    @include breakpoint(-lgk) {
                        height: 35px;
                        width: 35px;
                    }

                    &.active {
                        background-color: $color-blue1;
                        color: white;
                    }
                }
            }
        }
    }
}



.touchTypeContainer,
.productsGroupsContainer,
.cartproduct,
.pagercontainer,
.paymentTypecontainer,
.contactcontainer,
.ratingcontainer,
.cardStepcontainer,
.factorcontainer {
    position: absolute;
    // background: white;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    top: 0px;
    bottom: 0px;
    justify-content: flex-start;

    @include breakpoint(-md) {
        overflow: inherit;
    }

    @include breakpoint(-sm) {
        overflow-y: scroll;
    }
}

.paymentTypecontainer,
.cartproduct,
.pagercontainer,
.contactcontainer,
.ratingcontainer,
.cardStepcontainer,
.factorcontainer {
    z-index: 2;
    bottom: 100px;

    @include breakpoint(-lgk) {
        bottom: 50px;
    }
}

.productsGroupsContainer {
    overflow: auto;
}

.productShow {
    background: white;

    .product {
        -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        margin: 10px 50px;
        border-radius: 15px;
        padding: 15px;

        @include breakpoint(-md) {
            margin: 10px;
        }

        .detail {
            display: flex;

            .cnt {
                display: flex;
                flex-direction: column;
                margin: auto -20px auto 0px;

                .btn {
                    background-color: white;
                    border: 1px solid $color-blue1;
                    color: $color-blue1;
                    width: 70px;
                    height: 70px;

                    @include breakpoint(-md) {
                        width: 35px;
                        height: 35px;
                    }
                }

                &>span {
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    width: 100px;
                    margin: 15px;
                    background: white;
                    -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                    font-size: 30pt;

                    @include numberFont;

                    @include breakpoint(-md) {
                        height: 60px;
                        width: 60px;
                        margin: 10px;
                    }

                }
            }

            &>.content {
                padding: 10px 5px 0px 30px;
                margin-right: -70px;

                @include breakpoint(-md) {
                    margin-right: -55px;
                }

                .name {
                    text-align: center;
                    font-size: 35pt;
                    padding-left: 25%;
                    line-height: 40px;

                    @include breakpoint(-md) {
                        font-size: 20pt;
                        line-height: 30px;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    @include breakpoint(-md) {
                        justify-content: space-between;
                    }

                    .img {
                        width: 250px;
                        min-width: 250px;
                        height: 250px;

                        @include breakpoint(-md) {
                            width: 200px;
                            min-width:200px;
                            height: 200px;
                        }

                        @include breakpoint(-lgk) {
                            width: 200px;
                            min-width: 200px;
                            height: 200px;
                        }

                        &>img {
                            height: 100%;
                            width: 100%;
                            border-radius: 5px;
                        }
                    }

                    .text {
                        font-size: 16pt;
                        margin-right: 85px;
                        line-height: 25pt;
                        padding: 10px 0px;
                        text-align: justify;

                        @include breakpoint(-lgk) {
                            font-size: 12pt;
                            line-height: 20pt;
                            padding: 0px;
                        }

                        .description {
                            display: flex;
                            flex-direction: column;

                            span {
                                padding: 10px 0px;
                            }
                        }

                        .cal {
                            span:first-of-type {
                                color: $color-blue1;
                                padding: 0px 5px;
                            }
                        }
                    }
                }

                .prices {
                    display: flex;
                    justify-content: space-around;

                    .percentage {
                        background-image: url('../_assets/images/discount.svg');
                        background-size: cover;
                        margin: 5px;
                        width: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 20pt;
                        height: 60px;
                        padding: 0px 3px 0px 0px;
                    }


                    .price {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;

                        span:first-of-type {
                            font-size: 30pt;
                            line-height: 18px;
                            margin-top: 10px;
                            @include numberFont;

                            @include breakpoint(-md) {
                                font-size: 20pt;
                            }
                        }

                        span:last-of-type {
                            font-size: 18pt;
                            color: #9D9D9D;
                            padding: 5px 0px;
                        }
                    }

                    .mainprice {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        font-size: 20pt;
                    }

                    .mainprice:before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 50%;
                        right: 0;
                        border-top: 1px solid;
                        border-color: inherit;
                        color: red;

                        -webkit-transform: rotate(-5deg);
                        -moz-transform: rotate(-5deg);
                        -ms-transform: rotate(-5deg);
                        -o-transform: rotate(-5deg);
                        transform: rotate(-5deg);
                    }
                }
            }
        }

        .footer {

            .actions {
                display: flex;
                justify-content: space-evenly;

                .btn {
                    min-width: 200px;
                    padding: 25px 30px;
                    font-size: 18pt;
                    border-radius: 60px;

                    @include breakpoint(-md) {
                        min-width: 100px;
                        padding: 15px 30px;
                        font-size: 12pt;
                    }

                    &:last-of-type {
                        background-color: #F8A4A4;
                    }
                }
            }

            .explanationList {

                .TextField {
                    display: flex;
                    align-items: center;

                    &>div:nth-child(2) {
                        padding: 5px;
                        flex-grow: 1;

                        &>* {
                            width: 100%;
                        }
                    }
                }

                &>.slider {
                    background-color: white;
                    margin-right: 110px;

                    .item {
                        border-radius: 50px;
                        background-color: #34a0d9;
                        text-align: center;
                        color: white;
                        margin: 0px 10px 10px 10px;
                        width: calc(100% - 20px);
                        max-width: 150px;
                    }
                }
            }

            .relatedList {
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-around;

                .item {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid;
                    border-radius: 10px;
                    margin: 5px;
                    padding: 5px 10px;
                    align-items: stretch;

                    @include breakpoint(-lgk) {
                        padding: 5px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .name {
                            font-size: 12pt;
                            text-align: center;
                            flex-grow: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            max-height: 60px;

                            @include breakpoint(-lgk) {
                                font-size: 10pt;
                            }
                        }

                        .price {
                            text-align: center;
                            padding: 5px 0px;
                            font-size: 15pt;
                            display: flex;
                            flex-direction: column;

                            @include breakpoint(-lgk) {
                                font-size: 13pt;
                                padding: 0px;
                            }

                            span:last-of-type {
                                line-height: 20px;
                                color: #979797;
                            }

                            span:first-of-type {
                                @include numberFont;
                            }
                        }
                    }

                    .cnt {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .btn {
                            background-color: white;
                            border: 1px solid $color-blue1;
                            color: $color-blue1;
                            width: 35px;
                            height: 35px;

                            @include breakpoint(-md) {
                                width: 35px;
                                height: 35px;
                            }

                            @include breakpoint(-lgk) {
                                width: 30px;
                                height: 30px;
                                min-height: 30px;
                            }
                        }

                        &>span {
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 35px;
                            width: 35px;
                            margin: 5px;
                            background: white;
                            -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                            box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                            @include numberFont;

                            @include breakpoint(-md) {
                                height: 35px;
                                width: 35px;
                                margin: 5px;
                            }

                            @include breakpoint(-lgk) {
                                height: 30px;
                                width: 30px;
                            }
                        }
                    }
                }
            }


            .extra {
                display: flex;
                justify-content: center;
                padding: 10px;

                .item {
                    padding: 5px 15px;
                    text-align: center;
                    border: 1px solid #aaa;
                    border-radius: 5px;
                    background-color: #E9E9E9;
                    margin: 10px;

                    .name {
                        font-size: 15pt;
                    }

                    div {
                        .unit {
                            color: #A3A3A3;
                            font-size: 9pt;
                            padding: 0px 5px;
                        }
                    }
                }
            }
        }
    }
}

.cart {
    background: white;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .table {
        padding: 0px 15px;
        overflow: auto;
        flex-grow: 1;

        .img {
            height: 80px;
            width: 80px;

            @include breakpoint(-md) {
                height: 65px;
                width: 65px;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }

        font-size: 24pt;

        @include breakpoint(-md) {
            font-size: 14pt;
        }

        .action {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 85px;

            @include breakpoint(-md) {
                width: 100%;
                margin: 0px 0px 5px 0px;
            }

            .btn {
                background-color: white;
                color: $color-blue1;

                @include breakpoint(-lgk) {
                    height: 35px;
                    width: 35px;
                }
            }

            &>span {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-blue1;
                color: white;
                -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                font-size: 20pt;
                font-weight: bold;
                @include numberFont;

                @include breakpoint(-lgk) {
                    height: 35px;
                    width: 35px;
                    font-size: 17pt;
                }
            }

            &>* {
                margin: 0px 5px;

                @include breakpoint(-lgk) {
                    margin: 0px 3px;
                }
            }
        }
    }

    .sum {
        padding: 10px 20%;

        @include breakpoint(-md) {
            padding: 15px 10px;
        }

        &>div {
            display: flex;
            justify-content: space-between;
            color: $color-blue1;
            font-size: 18pt;

            @include breakpoint(-md) {
                font-size: 14pt;
            }

            .price {
                padding: 0px 5px;
                @include numberFont;
            }
        }

        &>div:nth-child(1),
        &>div:nth-child(2),
        &>div:nth-child(3) {
            font-size: 15pt;
        }

        &>div:nth-child(4) {
            font-size: 15pt;
        }
    }

    &>.action {
        display: flex;
        justify-content: space-evenly;
        padding: 10px;

        @include breakpoint(-md) {
            padding: 5px;
        }

        .button,
        .back {
            min-width: 264px;
            padding: 2px 30px;
            font-size: 25pt;
            border-radius: 90px;


            @include breakpoint(-md) {
                min-width: 150px;
                padding: 2px 5px;
                font-size: 14pt;
            }
        }



        .back {
            background-color: #F8A4A4;
        }
    }

    .floatBtn {
        position: absolute;
        bottom: 15px;
        left: 15px;
        display: flex;
        flex-direction: row-reverse;

        .btn {
            height: 110px;
            width: 110px;
            display: flex;
            flex-direction: column;
        }

        .text {
            background-color: #f50057;
            border-radius: 15px;
            padding: 15px;
            margin: 25px 10px 0px 10px;
            display: flex;
            align-items: flex-end;
        }
    }
}

.rating {

    .ratingList {

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #7b7979;
            border-radius: 15px;
            margin: 10px;

            .img {
                height: 100px;
                width: 100px;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;
                }
            }

            .ratingBox {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-grow: 1;
            }
        }
    }

    .commentSampleList {
        display: flex;
        flex-wrap: wrap;

.badComment{
padding: 5px; 
width: calc(50% - 10px);
margin: 5px;
.comment {
    .selected {
        background-color: #ff5a5a;
    }
}
}
.godComment{
    padding: 5px; 
    width: calc(50% - 10px);
    margin: 5px;
  
    .comment {
        .selected {
            background-color: #67ff8f;
        }
    }
}

        .comment {
            background-color: #ececec;
            border-radius: 5px;
            width: 100%;
            background-color: white;
            margin: 5px;
            .btn {
                width: 100%;
            }

            .selected {
                color: white;
            }
        }
    }

    .commentAll {
        padding: 10px;
        direction: rtl;
    }
}

.contact {
    .info {
        font-size: 25pt;

        span:first-of-type {
            font-size: 22pt;
            color: rgb(105, 105, 105);
        }
    }
}

.contact,
.rating {

    .field {
        margin: 20px 0px;
        width: 500px;
    }

    .active {
        background-color: #e9f7ff;
    }
}

.pager,
.contact,
.rating {
    background: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
        color: $color-blue1;
        font-size: 25pt;

        @include breakpoint(-md) {
            font-size: 15pt;
        }
    }

    .numpad {
        width: 500px;
        padding: 20px;
        text-align: center;
        font-size: 25pt;
        direction: ltr;

        @include breakpoint(-lgk) {
            width: 450px;
            font-size: 15pt;
        }

        @include breakpoint(-sm) {
            width: 300px;
            font-size: 15pt;
        }

        .button {
            width: 100%;
            padding: 2px 30px;
            font-size: 25pt;

            @include breakpoint(-md) {
                padding: 2px 5px;
                font-size: 14pt;
            }
        }
    }

    .action {
        padding: 15px 0px;
        display: flex;
        justify-content: space-between;

        .button {
            border-radius: 50px;
            width: auto;
            min-width: 250px;
        }

        .back {
            background-color: #F79191;
            min-width: 200px;
            padding: 2px 30px;
            font-size: 25pt;
            border-radius: 90px;

            @include breakpoint(-md) {
                min-width: 150px;
                padding: 2px 5px;
                font-size: 14pt;
            }
        }
    }
}

.paymentType {
    background: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: $color-blue1;
    padding: 200px;

    @include breakpoint(-md) {
        padding: 10px;
    }

    .price {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 15px;
        font-size: 24pt;
        align-items: center;

        @include breakpoint(-lgk) {
            font-size: 20pt;
        }

        @include breakpoint(-sm) {
            font-size: 14pt;
        }

        span {
            .unit {
                padding: 0px 5px;
            }

            .amount {
                font-size: 21pt;

                @include breakpoint(-sm) {
                    font-size: 14pt;
                }
            }
        }
    }

    .selectType {
        border: 1px solid #999;
        border-radius: 15px;
        padding: 20px;
        width: 100%;

        .title {
            padding: 15px;
            font-size: 20pt;
        }

        .type {
            display: flex;
            justify-content: space-around;

            img {
                width: 250px;
                height: 250px;
                border-radius: 10px;

                @include breakpoint(-lgk) {
                    width: 200px;
                    height: 200px;
                }

                @include breakpoint(-sm) {
                    width: 150px;
                    height: 150px;
                }
            }

            .card {
                padding: 20px;
                text-align: center;
                color: #555;
                font-size: 20pt;
                cursor: pointer;

                h3 {
                    padding: 5px;
                }

                @include breakpoint(-sm) {
                    padding: 5px;
                    font-size: 10pt;
                }
            }

            .cash {
                padding: 20px;
                text-align: center;
                color: #555;
                font-size: 20pt;
                cursor: pointer;

                h3 {
                    padding: 5px;
                }

                @include breakpoint(-sm) {
                    padding: 5px;
                    font-size: 10pt;
                }
            }

            .card:hover>.img,
            .cash:hover>.img {
                -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            }
        }
    }

    .action {
        padding: 15px 0px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;

        .button {
            border-radius: 50px;
            width: auto;
        }

        .back {
            border-radius: 50px;
            width: auto;
            background-color: #F79191;

            min-width: 264px;
            padding: 2px 30px;
            font-size: 25pt;
            border-radius: 90px;


            @include breakpoint(-md) {
                min-width: 150px;
                padding: 2px 5px;
                font-size: 14pt;
            }
        }
    }
}

.cardStep {
    background: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: $color-blue1;
    padding: 200px;

    @include breakpoint(-md) {
        padding: 20%;
    }

    img {
        width: 100%;
    }

    .action {
        padding: 15px 0px;
        display: flex;
        justify-content: left;
        width: 100%;

        .button {
            border-radius: 50px;
            width: auto;
        }

        .back {
            border-radius: 50px;
            width: auto;
            background-color: #F79191;

            min-width: 264px;
            padding: 2px 30px;
            font-size: 25pt;
            border-radius: 90px;


            @include breakpoint(-md) {
                min-width: 150px;
                padding: 2px 5px;
                font-size: 14pt;
            }
        }
    }
}

.factorContext {
    transform: translateX(100%);
    height: 100%;

    -webkit-animation: slide 0.8s forwards;
    // -webkit-animation-delay: 2s;
    animation: slide 0.8s forwards;
    // animation-delay: 2s;
}

@-webkit-keyframes slide {
    100% {
        transform: translateX(0);
    }
}

@keyframes slide {
    100% {
        transform: translateX(0);
    }
}

.factor {
    background: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 200px;
    direction: ltr;
    height: 100%;

    .action {
        padding: 15px 0px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .button {
            border-radius: 50px;
            width: auto;
        }

        .back {
            border-radius: 50px;
            width: auto;
            background-color: #F79191;
        }
    }

    .goodbye {
        font-size: 40pt;
        padding: 30px;

        @include breakpoint(-lgk) {
            font-size: 22pt;
        }
    }
}

footer {
    height: 100px;
    background-image: linear-gradient(to left, #7E2D7A, rgb(48, 17, 46));
    display: flex;
    align-items: center;
    z-index: 1;

    @include breakpoint(-lgk) {
        height: 50px;
    }

    .switch {
        margin: 0px 10px;

        button{
            max-height: 38px;
        }
    }

    &>img {
        height: calc(100% - 10px);
        margin: 5px;
    }

    &>span {
        flex-grow: 1;
        text-align: center;
        font-size: 30pt;
        color: white;

        @include breakpoint(-lgk) {
            font-size: 20pt;
        }
    }

    @include breakpoint(-sm) {
        height: 50px;

        &>span {
            font-size: 17pt;
        }
    }


}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.numberFont ,.numberFont p ,.numberFont input{
    @include numberFont;
    font-size: 1.5rem;
}