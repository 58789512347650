@import '../../_styles/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .btn{
        margin: 15px;
    }

    .main {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-evenly;
        flex-grow: 1;

        @include breakpoint(-md) {
            flex-direction: column;
        }

        .pageStyle {
            flex-grow: 1;
        }

        .keypadcontainer {
            direction: ltr;
            padding: 30px;
            margin: auto;
            min-width: 400px;

            .keypad {
                margin: auto;
            }

            @include breakpoint(-sm) {
                padding: 0px;
                min-width: 100%;
            }
        }
    }
}