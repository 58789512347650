@import '../../_styles/vars.scss';

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;

    .button {
        padding: 30px 50px;
    }
}