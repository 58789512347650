@import '../../_styles/vars.scss';

    .container {
        display: flex;
        width: 35%;
        flex-direction: column;
        align-items: stretch;
        margin: auto;
        
        @include breakpoint(lg) {
            width: 450px;
        }

        @include breakpoint(-lg) {
            width: 40%;
        }

        @include breakpoint(-md) {
            width: 50%;
        }

        @include breakpoint(-sm) {
            width: 90%;
        }

        .header {
            text-align: center;
            margin: 0px 15px;

            .img {
                padding: 20px;
                // img{
                //     @include breakpoint(-sm) {
                //         height: 150px;
                //         resize: both;
                //     }
                //}
            }

            .title {
                border-bottom: 1px solid #DADADA;
                line-height: .1em;
                text-align: center;
                margin-bottom: 15px;

                span {
                    background: #FFF;
                    padding: 0 10px;
                }
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            padding: 15px;
        }

        .actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn {
                height: 85px;
                width: 100%;
                border-bottom: 1px solid #eee;
                padding: 15px;

                Button {
                    width: 100%;
                    height: 100%;
                }
            }

            .bottom{
                margin: 15px;
            }
        }

        .copyRight{
            display: flex;
            justify-content:center;
            margin-bottom: 15px;
        }

        .copyRightLink{
            color: blue;
            cursor: pointer;
        }

    }
// }
